import React from "react"
import PropTypes from "prop-types"
// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import PostCard from "../components/post-card"

const Tags = ({ location, pageContext, data }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
	const subtitle = data.site.siteMetadata.subtitle
  const { tag } = pageContext
  let { edges } = data.allMarkdownRemark
	const posts = edges.concat(data.allPosts.edges)
  const tagHeader = `Posts tagged with "${tag}"`
  return (
		<Layout location={location} title={siteTitle} subtitle={subtitle}>
      <h1>{tagHeader}</h1>
			<Link to="/tags">All tags</Link>
      <ol className="post-list">
        {posts.map(({ node }) => {
					const frontmatter = node.frontmatter || node
          const title = frontmatter.title
					const slug = node.fields.slug ? node.fields.slug : `/${node.slug}/`
          return (
						<PostCard
								key={node.id}
								slug={slug}
								title={title}
								date={frontmatter.date}
								description={frontmatter.description}
								tags={frontmatter.tags || []}
								devTo={node.fields.dev_to}
						/>
          )
        })}
      </ol>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags
export const pageQuery = graphql`
  query($tag: String) {
		site {
      siteMetadata {
        title
				subtitle
      }
    }
		allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
					id
          fields {
            slug
          }
          frontmatter {
						date(formatString: "MMMM DD, YYYY")
	          title
	          description
						tags
          }
        }
      }
    }
		allPosts(
			limit: 2000
			filter: { tag_list: { in: [$tag] } }
		) {
			edges {
				node {
					id
					title
					description
					date: published_at(formatString: "MMMM DD, YYYY")
					tags: tag_list
					slug
					fields {
						dev_to
					}
				}
			}
		}
  }
`
